import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import styles from '../css/single-news.module.css'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';


const NewsTemplate = ({ data }) => {
    const { titel, datum, beschrijving: { json }} = data.contentfulNieuws;
    const options = {
        renderNode: {
            "embedded-asset-block": (node) => {
                return (
                    <div className="news-image">
                      <img width="400" alt="afbeelding" src={node.data.target.fields.file['en-US'].url} />
                    </div>)
            }
        }
    }
    return (
        <Layout>
            <section className={styles.news}>
                <div className={styles.center}>
                    <h1>{titel}</h1>
                    <h4>Datum: {datum}</h4>
                    <article className={styles.post}>
                        {
                            documentToReactComponents(json, options)
                        }
                    </article>
                    <AniLink fade to="/nieuws" className="btn-primary">Terug</AniLink>
                </div>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query getNews($slug: String) {
        contentfulNieuws(slug: { eq: $slug }) {
            titel
            datum(formatString: "DD-MM-YYYY")
            beschrijving {
                json
            }
        }
    }
`
export default NewsTemplate


